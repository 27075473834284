import { graphql } from 'gatsby'
import React from 'react'
import get from 'lodash/get'

import Meta from 'components/meta'
import ProductsPage from 'organisms/ProductsPage'
import Layout from 'components/layout'

export default function Page({ data }) {
  const metadata = get(data, 'site.metadata')

  return (
    <Layout>
      <Meta site={metadata} title="Product" />
      <ProductsPage products={data.products.edges} />
    </Layout>
  )
}

export const query = graphql`
  query ProductsPageQuery {
    site {
      metadata: siteMetadata {
        title
        description
        siteUrl
      }
    }
    products: allMarkdownRemark(sort: { fields: frontmatter___id }) {
      edges {
        node {
          frontmatter {
            image {
              childImageSharp {
                fluid(maxWidth: 510) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            id
            name
            type
            subType
            detail
            price
            unit
          }
        }
      }
    }
  }
`
