import React, { useMemo } from 'react'
import * as R from 'ramda'
import TransitionLink from 'gatsby-plugin-transition-link'
import styled from 'styled-components'
import classNames from 'classnames'

import { PAGE_TRANSITION_DURATION } from 'components/layout'
import Icon from 'components/icon'

const breakpoint = 'lg'
const fontClassName = `ms0 ms2-${breakpoint}`

const entry = {
  length: PAGE_TRANSITION_DURATION.fade,
  state: {
    transitionMethod: 'fade',
  },
}

const exit = {
  length: PAGE_TRANSITION_DURATION.fade,
}

const typeLabelMap = {
  gate: 'ประตู',
  roof: 'หลังคา',
  fence: 'รั้ว',
  handrail: 'ราวบันได',
  gutter: 'รางน้ำ',
  'iron-grill': 'เหล็กนิรภัย',
  'electric-gate': 'ประตูรีโมท',
  'spare-part': 'อะไหล่',
  'window-film': 'ฟิล์มกันแดด',
  'bug-window-screen': 'มุ้งลวด',
}

const typeOrderMap = {
  gate: 1,
  roof: 2,
  fence: 3,
  handrail: 4,
  gutter: 5,
  'iron-grill': 6,
  'electric-gate': 7,
  'spare-part': 8,
  'window-film': 9,
  'bug-window-screen': 10,
}

export default function ProductsPage({ products }) {
  const productTypes = useMemo(
    () =>
      R.pipe(
        R.map(R.path(['node', 'frontmatter', 'type'])),
        R.uniq,
        R.sortBy(sorter(typeOrderMap))
      )(products),
    [products]
  )

  return (
    <div className="bg-white flex-grow-1">
      <div className={`container my-ms2 mt-${breakpoint}-ms3`}>
        <h1 className="text-center text-primary">สินค้า</h1>
        <div className="mt-ms2">
          <Grid className="grid">
            {productTypes.map(productType => {
              const productTypeLabel = typeLabelMap[productType]
              return (
                <TransitionLink
                  to={`/products/${productType}/`}
                  key={productType}
                  exit={exit}
                  entry={entry}
                >
                  <ProductTypeIcon key={productType}>
                    <div className={`content ${fontClassName}`}>
                      <Icon.Svg name={productType} />
                    </div>
                  </ProductTypeIcon>
                  <p className={classNames('text-center mt-ms-3', fontClassName)}>
                    {productTypeLabel}
                  </p>
                </TransitionLink>
              )
            })}
          </Grid>
        </div>
      </div>
    </div>
  )
}

const Grid = styled.div`
  --item-min-width: 0;
  --grid-gap: calc(var(--ms-2) * 1em) calc(var(--ms0) * 1em);
  --column-count: 3;

  @media (min-width: ${({ theme }) => theme.breakpoints[breakpoint]}) {
    --column-count: 4;
    --grid-gap: calc(var(--ms1) * 1em) calc(var(--ms4) * 1em);
  }
`

const ProductTypeIcon = styled.div`
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 100%;
  }

  > .content {
    --color: var(--gray);
    --border-color: rgba(var(--gray-rgb), 0.7);
    --hover-color: var(--primary);
    --hover-border-color: rgba(var(--primary-rgb), 0.7);

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 2px solid var(--border-color);
    border-radius: calc(var(--ms0) * 1em);
    padding: calc(var(--ms-1) * 1em);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--color);
    cursor: pointer;
    transition: all var(--transition-duration);

    &:hover {
      border: 2px solid var(--hover-border-color);
      color: var(--hover-color);
    }
  }
`

function sorter(orderMap) {
  return productType => {
    return orderMap[productType]
  }
}
